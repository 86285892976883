@tailwind base;
@tailwind components;
@tailwind utilities;

.mdxeditor-popup-container {
  display: none;
}

/* Tolgee add white-space: pre-line to all <T /> components to preserve white space in translations */
*[_tolgee="true"] {
  white-space: pre-line;
}

html {
  @apply scroll-smooth;
}
body {
  margin: 0;
  letter-spacing: 0rem !important;
  font-family: Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
  scroll-margin-top: 4.5rem;
}

h1 {
  @apply mt-4 mb-4 text-2xl font-bold;
  letter-spacing: -0.03em;
}

h2 {
  @apply mt-4 mb-4 text-xl font-bold;
}

h3 {
  @apply mt-4 mb-4 text-lg font-bold;
}

h4 {
  @apply mt-4 mb-4 text-lg font-semibold;
}

select,
input,
textarea,
a {
  @apply transition duration-200 ease-in-out;
}

a {
  @apply no-underline text-turquoise-500;
}
p {
  @apply my-4;
}
ul {
  @apply my-4 list-none;
  padding-inline-start: 40px;
}
.quickview ul {
  @apply list-disc;
}

select {
  text-overflow: ellipsis;
}

.button-primary {
  @apply box-border inline-block w-40 px-4 py-2 overflow-hidden text-lg font-semibold text-white transition duration-150 ease-in-out border border-solid rounded-md cursor-pointer no-select bg-prighterblue whitespace-nowrap overflow-ellipsis border-prighterblue hover:bg-white hover:text-prighterblue active:bg-gray-500;
}
.button-secondary {
  @apply box-border inline-block w-40 px-4 py-2 overflow-hidden text-base text-white transition duration-150 ease-in-out border border-solid rounded-md cursor-pointer no-select bg-prighterblue whitespace-nowrap overflow-ellipsis border-prighterblue hover:bg-white hover:text-prighterblue active:bg-gray-500;
}

.box-outerlayout:target {
  @apply animate-target;
}

.secondarybox-outerlayout {
  @apply p-4 space-y-2 border border-solid rounded border-brand-100;
}

.box-innerlayout {
  @apply p-4 border border-solid rounded border-brand-100;
}

.box-outerlayout {
  @apply relative grid p-6 pt-2 bg-white border border-gray-200 border-solid rounded-lg shadow-xl;
}

.box-outerlayout h1,
.box-outerlayout h2,
.box-outerlayout h3,
.box-outerlayout strong {
  @apply text-brand-900;
}
.box-outerlayout p,
.box-outerlayout div,
.box-outerlayout {
  @apply text-gray-800;
}

.box-dotted {
  @apply relative p-4 bg-white border border-dashed border-gray-200 rounded-lg;
}

/* Focus */
select:focus,
button:focus,
input:focus,
textarea:focus/*,
a:focus*/ {
  @apply outline-none;
  box-shadow: 0 0 0 0 calc(0px + 2px) #2c5282;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

/* Checkbox Icon for custom Boolean */
input[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
}

input[type="text"]:read-only,
input[type="email"]:read-only,
input[type="tel"]:read-only {
  @apply bg-white shadow-none outline-none;
}
input[type="text"]:focus:read-only,
input[type="email"]:focus:read-only,
input[type="tel"]:focus:read-only {
  box-shadow: none !important;
  @apply bg-white border-gray-400 outline-none;
}

.navbar-item {
  @apply grid items-center justify-start grid-flow-col gap-2 px-2 py-1 text-sm font-medium transition-all duration-150 rounded hover:bg-gray-200 hover:text-gray-700;
}

.no-select {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.highlighted strong {
  z-index: -1;
  position: relative;
  margin: 0 -0.4em;
  padding: 0.1em 0.4em;
  border-radius: 0.8em 0.3em;
  background: transparent;
  background-image: linear-gradient(
    90deg,
    rgba(64, 224, 208, 0.1),
    rgba(64, 224, 208, 0.7) 4%,
    rgba(64, 224, 208, 0.3)
  );
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
.highlighted-orange {
  z-index: -1;
  position: relative;
  margin: 0 -0.4em;
  padding: 0.1em 0.4em;
  border-radius: 0.8em 0.3em;
  background: transparent;
  background-image: linear-gradient(
    90deg,
    rgba(255, 140, 0, 0.1),
    rgba(255, 140, 0, 0.8) 4%,
    rgba(255, 140, 0, 0.3)
  );
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}

@import "../tailwind.css";
