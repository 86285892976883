.input-container textarea {
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  background-color: transparent;
}

.input-container textarea:focus {
  border-color: rgb(var(--color-prighterblue));
  box-shadow: 0 0 3px 1px rgb(var(--color-prighterblue));
}

.input-container .filled {
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transform: translate(0, -5px) scale(0.8);
}

.input-container:focus-within label {
  transform: translate(0, -5px) scale(0.8);
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  background-color: white;
  color: rgb(var(--color-prighterblue));
}

.input-container label {
  transform: translate(4px, 22px) scale(1);
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}
